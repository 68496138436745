@import url(https://fonts.googleapis.com/css?family=Lato);
$font-family-sans-serif: 'Lato', sans-serif;
$font-size-base:          14px !default;
$font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1:            floor(($font-size-base * 2.6)) !default; // ~36px
$font-size-h2:            floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3:            ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4:            ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5:            $font-size-base !default;
$font-size-h6:            ceil(($font-size-base * 0.85)) !default; // ~12px

$navbar-height: 80px;
$navbar-default-bg: #f4f4f4;
$gray-base:              #292929 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             #4E5D6C !default;   // #999
$gray-lighter:           #EBEBEB !default; // #eee

$brand-primary:         #DF691A !default;
$brand-success:         #5cb85c !default;
$brand-info:            #5bc0de !default;
$brand-warning:         #f0ad4e !default;
$brand-danger:          #d9534f !default;


$line-height-base: 1.6;

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.navbar {
  margin:0px;
}

.navbar-brand{
  height:auto;
  padding:15px 15px;
  font-size:25px!important;
  font-weight:normal;
  font-weight:600;
}
.navbar-brand img{
  max-height: 200px;
  margin: 0 5px 0 0;
  display:inline;
}
.navbar-brand{
  padding: 15px 15px;
}
.navbar .nav{
  padding-top: 2px;
  margin-right: -16px;
  float:right;
  z-index:1;
}

.bloc{
  width:100%;
  clear:both;
  background: 50% 50% no-repeat;
  padding:0 50px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position:relative;
}

/* Sizes */

.bloc-lg{
  padding:100px 50px;
}
.bloc-md{
  padding:50px;
}
.bloc-sm{
  padding:20px 50px;
}

/*  Rounded Image */
.img-rd{
  border-radius:6px;
}
.img-rd-md{
  border-radius:20px;
}
.img-rd-lg{
  border-radius:34px;
}


/* Product stuff */

.productbox {
  background-color:#ffffff;
  padding:10px;
  margin-bottom:10px;
  -webkit-box-shadow: 0 8px 6px -6px  #999;
  -moz-box-shadow: 0 8px 6px -6px  #999;
  box-shadow: 0 8px 6px -6px #999;
}

.producttitle {
  font-weight:bold;
  padding:5px 0 5px 0;
}

.productprice {
  border-top:1px solid #dadada;
  padding-top:5px;
}

.pricetext {
  font-weight:bold;
  font-size:1.4em;
}